/*
 * @Author: wyx
 * @Date: 2023-11-10 14:08:02
 * @LastEditTime: 2024-03-04 14:06:21
 * @LastEditors: wenwen
 * @Description: aoi接口
 */
import {
  post,
  get
} from '@/utils/request'
const path = '/train'
const API = {
  getUserList: '/user/user/selectAll', // 获取组织架构人员
  getMenusList: '/permission/menu/menus', // 采购计划订单详情
  getUserInfo: '/permission/user/currentUser', // 获取用户信息
  getDartment: '/user/department/departments', // 获取部门带人员
  getManageFrameDept: '/user/department/tree', // 获取部门树形
  getManageDeptList: path + '/hrAdminConfiguration/getByDept', // 根据用户token 获取可见范围下拉部门筛选
  getOperationRecord: path + '/trOptLog/findByPage', // 查询操作记录
  getAddOperateRecord: path + '/trOptLog/add', // 新增操作记录
  getNotification: path + '/employeeExam/examResultMessage', // 获取通知列表
  getTrainingRecordList: path + '/employeeTrain/findByPage'// 获取培训记录
}
export const getUserListApi = (data) => get(API.getUserList, data)
export const getMenusApi = (data) => get(API.getMenusList, data)
export const getUserInfoApi = (data) => get(API.getUserInfo, data)
export const getDartmentApi = (data) => get(API.getDartment, data)
export const getManageFrameDeptApi = (data) => get(API.getManageFrameDept, data)
export const getManageDeptListApi = (data) => post(API.getManageDeptList, data)
export const getOperationRecordApi = (data) => post(API.getOperationRecord, data)
export const getAddOperateRecordApi = (data) => post(API.getAddOperateRecord, data)
export const getNotificationApi = (data) => get(API.getNotification, data)
export const getTrainingRecordListApi = (data) => post(API.getTrainingRecordList, data)
