/*
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-06-09 14:50:15
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-18 14:29:05
 */
import Vue from 'vue'
import Vuex from 'vuex'
import settings from './modules/settings'

Vue.use(Vuex)

const store = new Vuex.Store({

  state: {
    /* 所有路由 */
    routersList: null,
    // 用户信息
    userList: [],
    /* 所有权限 */
    permissionList: null,
    // 角色权限信息
    rolesList: [],
    // 线索数据
    clueData: {},
    // 门店列表
    shopList: [],
    // 部门树列表
    deptTreeList: [],
    // 渠道列表
    channelList: [],
    projectFlag: null
  },
  mutations: {
    /* 所有路由 */
    SET_ROUTERS (state, routes) {
      state.routersList = routes
    },
    // 用户信息
    SET_USERS (state, users) {
      state.userList = users
    },
    /* 所有权限 */
    SET_PERMISSION (state, permission) {
      state.permissionList = permission
    },
    /* 角色权限信息 */
    SET_ROLES (state, roles) {
      state.rolesList = roles
    },
    SET_CLUBDATA (state, clueData) {
      state.clueData = clueData
    },
    SET_SHOPLIST (state, clueData) {
      state.shopList = clueData
    },
    SET_DEPTTREELIST (state, clueData) {
      state.deptTreeList = clueData
    },
    SET_CHANNELLIST (state, clueData) {
      state.channelList = clueData
    },
    // 获取项目标识
    SET_PROJECTFLAG (state, data) {
      state.projectFlag = data
    }
  },
  getters: {
    getRouters: (state) => state.routersList,
    getUserList: (state) => state.userList,
    getPermission: (state) => state.permissionList,
    getRolesList: (state) => state.rolesList,
    getClueData: (state) => state.clueData
  },
  modules: {
    settings
  }
})

export default store
