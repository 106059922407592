<template>
  <!-- 公共头部组件 -->
  <div class="header">
    <!-- 折叠按钮 -->
    <!-- <div class="collapse-btn" @click="collapseChage"> -->
      <!-- 通过v-if和v-else来判定侧边栏打开状态 -->
      <!-- <i v-if="!collapse" class="el-icon-s-fold"></i>
      <i v-else class="el-icon-s-unfold"></i>
    </div> -->

    <!-- 系统logo -->
    <div :class="{'logoOpen':isOpen,'logoClose':!isOpen}">
      <div class="logo-img" :style="{'background':$store.state.settings.theme}">
        <img src="../../assets/logo.png"/>
      </div>
      <div v-show="isOpen" class="logo-title">英奢集团</div>
    </div>
    <!-- 头部组件右边功能区 -->
    <div class="header-right">
      <div class="header-user-con">
        <!-- <div @click="drawer = true" class="skin">
          <svg t="1686640087150" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2430" width="25" height="25"><path d="M772.8 96v64l163.2 161.6-91.2 91.2c-12.8-11.2-27.2-16-43.2-16-36.8 0-65.6 28.8-65.6 65.6V800c0 35.2-28.8 64-64 64H352c-35.2 0-64-28.8-64-64V462.4c0-36.8-28.8-65.6-65.6-65.6-16 0-32 6.4-43.2 16l-91.2-91.2L249.6 160h40l1.6 1.6C336 228.8 420.8 272 512 272c91.2 0 176-41.6 220.8-110.4 0-1.6 1.6-1.6 1.6-1.6h38.4V96M291.2 96H256c-22.4 0-38.4 6.4-49.6 19.2L43.2 276.8c-25.6 25.6-25.6 65.6 0 89.6l94.4 94.4c11.2 11.2 27.2 17.6 41.6 17.6s30.4-6.4 41.6-17.6h1.6c1.6 0 1.6 0 1.6 1.6V800c0 70.4 57.6 128 128 128h320c70.4 0 128-57.6 128-128V462.4c0-1.6 0-1.6 1.6-1.6h1.6c11.2 11.2 27.2 17.6 41.6 17.6 16 0 30.4-6.4 41.6-17.6l94.4-94.4c25.6-25.6 25.6-65.6 0-89.6L819.2 115.2c-12.8-12.8-28.8-19.2-46.4-19.2h-40c-22.4 0-41.6 11.2-54.4 30.4-33.6 49.6-96 81.6-168 81.6s-134.4-33.6-168-81.6C332.8 107.2 312 96 291.2 96z" fill="#999" p-id="2431"></path></svg>
        </div> -->
        <el-popover
          v-model="popshow"
          placement="bottom"
          title="请选择主题色"
          width="150px"
          trigger="click">
          <div style="display: flex;justify-content: center;align-items: center;">
            <theme-picker @onThemeChange="_colorChange" @change="_colorChangeSubmit"/>
          </div>

          <div slot="reference" class="skin">
            <span class="iconfont icon-icon_skin" :style="{'color':$store.state.settings.theme}"></span>
            <!-- <svg t="1686640087150" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2430" width="25" height="25"><path d="M772.8 96v64l163.2 161.6-91.2 91.2c-12.8-11.2-27.2-16-43.2-16-36.8 0-65.6 28.8-65.6 65.6V800c0 35.2-28.8 64-64 64H352c-35.2 0-64-28.8-64-64V462.4c0-36.8-28.8-65.6-65.6-65.6-16 0-32 6.4-43.2 16l-91.2-91.2L249.6 160h40l1.6 1.6C336 228.8 420.8 272 512 272c91.2 0 176-41.6 220.8-110.4 0-1.6 1.6-1.6 1.6-1.6h38.4V96M291.2 96H256c-22.4 0-38.4 6.4-49.6 19.2L43.2 276.8c-25.6 25.6-25.6 65.6 0 89.6l94.4 94.4c11.2 11.2 27.2 17.6 41.6 17.6s30.4-6.4 41.6-17.6h1.6c1.6 0 1.6 0 1.6 1.6V800c0 70.4 57.6 128 128 128h320c70.4 0 128-57.6 128-128V462.4c0-1.6 0-1.6 1.6-1.6h1.6c11.2 11.2 27.2 17.6 41.6 17.6 16 0 30.4-6.4 41.6-17.6l94.4-94.4c25.6-25.6 25.6-65.6 0-89.6L819.2 115.2c-12.8-12.8-28.8-19.2-46.4-19.2h-40c-22.4 0-41.6 11.2-54.4 30.4-33.6 49.6-96 81.6-168 81.6s-134.4-33.6-168-81.6C332.8 107.2 312 96 291.2 96z" fill="#999" p-id="2431"></path></svg> -->
          </div>
          <!-- <el-button slot="reference">click 激活</el-button> -->
        </el-popover>
        <!-- 全屏显示 -->
         <!--tooltip提供了两个主题：dark和light，通过 effect 设置主题 -->
          <!-- 通过三元表达式来设置不同的文字提示，placement属性控制文字提示出现的位置 -->
        <div class="btn-fullscreen" @click="_handleFullScreen">
          <el-tooltip
            effect="dark"
            :content="fullscreen?`取消全屏`:`全屏`"
            placement="bottom"
          >
            <i class="el-icon-rank" :style="{'color':$store.state.settings.theme}"></i>
          </el-tooltip>
        </div>

        <!-- 消息中心 -->
        <!-- 通过对message的判定，来决定是否显示小红点 -->
        <!-- <div class="btn-bell">
          <el-tooltip
            effect="dark"
            :content="message?`有${message}条未读消息`:`消息中心`"
            placement="bottom"
          >
            <router-link to="/tabs">
              <i class="el-icon-bell"></i>
            </router-link>
          </el-tooltip>
          <span class="btn-bell-badge" v-if="message"></span>
        </div> -->

        <!-- 用户头像 -->
        <div class="user-avator">
          <!-- <img src="../../assets/img/login-bg.jpg" /> -->
          <span :style="{'background':$store.state.settings.theme}" class="header-img">{{ username.split('')[username.length - 1] }}</span>
        </div>
        <!-- 用户名下拉菜单：trigger属性配置 click 激活 command：点击菜单项触发的事件回调 -->
        <el-dropdown class="user-name" trigger="click" @command="_handleCommand">
          <span class="el-dropdown-link">
            {{username}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <!-- slot设置下拉列表 -->
          <el-dropdown-menu slot="dropdown">
            <!-- <a
              href="#"
              target="_blank"
            >
              <el-dropdown-item>返回工作台</el-dropdown-item>
            </a> -->
            <el-dropdown-item command="backHome"
              >返回工作台</el-dropdown-item
            >
            <el-dropdown-item divided command="loginout"
              >退出登录</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>
<script>
// 加载bus实现组件通信
import bus from '../../utils/bus'
import ThemePicker from '@/components/ThemePicker'
export default {
  components: { ThemePicker },
  data () {
    return {
      collapse: false,
      fullscreen: false,
      name: '刘文博',
      message: 2,
      drawer: false,
      loading: false,
      form: {
        name: '',
        region: '',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '80px',
      timer: null,
      isOpen: true,
      popshow: false
    }
  },

  // 监控自定义属性
  computed: {
    username () {
      // 从本地存储中获取值
      // const username = localStorage.getItem('ms_username')
      // // 对获取到的值进行判断，如果从本地存储能够获取到值，则使用该值，如果不能获取到，则使用 data 中设置的值
      // return username || this.name
      return this.$store.state.userList.userName
    }
  },
  created () {
  },
  methods: {
    // 用户名下拉菜单选择事件
    _handleCommand (command) {
      // 退出登录事件
      if (command === 'loginout') {
        // 从本地存储中删除用户名
        localStorage.removeItem('ms_username')
        // 跳转到登录页面
        this.$router.push('/login')
      }
      // 退出登录事件
      if (command === 'backHome') {
        window.location.href = process.env.VUE_APP_API_BASE_CONSOLE
      }
    },

    // 侧边栏折叠
    // collapseChage () {
    //   this.collapse = !this.collapse
    //   // 通过bus发送信息
    //   bus.$emit('collapse', this.collapse)
    // },

    // 全屏事件
    _handleFullScreen () {
      // 获取文档对象的根元素
      const element = document.documentElement
      if (this.fullscreen) {
        // 设置不同浏览器下的退出全屏模式
        // -webkit- 兼容chrome 和 safari
        // -moz- 兼容firefox
        // -ms- 兼容IE浏览器
        // -o- 兼容opera
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      } else {
        // 设置不同浏览器下的进入全屏模式
        if (element.requestFullscreen) {
          element.requestFullscreen()
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen()
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen()
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen()
        }
      }
      this.fullscreen = !this.fullscreen
    },
    _handleClose (done) {
      this.drawer = false
    },
    _cancelForm () {
      this.loading = false
      this.drawer = false
      clearTimeout(this.timer)
    },
    _colorChange (val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      })
      this.theme = val
      bus.$emit('theme', this.theme)
      // this.drawer = false
    },
    // 颜色选择点击确定事件
    _colorChangeSubmit () {
      this.popshow = false
    }
  },

  // 初始化页面完成后，对页面可见区域宽度进行判定，如果页面宽度小于1500，则触发 collapseChage 方法。
  mounted () {
    if (document.body.clientWidth < 1500) {
      // this.collapseChage()
      this.isOpen = false
    }
    bus.$on('collapse-content', (msg) => {
      this.collapse = msg
      if (this.collapse) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
    })
    // 通过 bus 通信获取 message 的值。
    bus.$on('msg', (e) => {
      this.message = e
    })
  }
}
</script>

<!-- CSS样式 -->
<style scoped>
  .header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 56px;
    font-size: 22px;
    border-bottom: 1px solid #f3f3f3;
    /* color: #fff; */
  }
  .collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 56px;
  }
  .logoOpen,.logoClose {
    cursor: pointer;
    float: left;
    width: 208px;
    height: 56px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #f3f3f3;
  }
  .logoClose {
    width: 64px;
  }
  .logo-img{
    background: #0089FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }
  .logo-img img{
    width: 28px;
    height: 28px;
  }
  .logo-title{
    font-size: 18px;
    font-weight: 500;
    color: #333333;
    margin-left: 8px;
    line-height: 2;
  }
  .header-right {
    float: right;
    padding-right: 24px;
  }
  .header-user-con {
    display: flex;
    height: 56px;
    align-items: center;
  }
  .skin{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .skin span {
    font-size: 19px
  }
  .btn-fullscreen {
    transform: rotate(45deg);
    margin-left: 16px;
    margin-right: 5px;
    font-size: 24px;
  }
  .btn-bell,
  .btn-fullscreen {
    position: relative;
    width: 30px;
    height: 25px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
  }
  .btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    /* color: #fff; */
  }
  .btn-bell .el-icon-bell {
    color: #333;
  }
  .user-name {
    margin-left: 10px;
  }
  .user-avator {
    margin-left: 20px;
  }
  .user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .header-img{
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 8px 10px; */
    width: 30px;
    height: 30px;
    border-radius: 100%;
    color: #fff;
    background: #0089FF;
    font-size: 14px;
    font-weight: 400;
  }
  .el-dropdown-link {
    color: #333;
    cursor: pointer;
  }
  .el-dropdown-menu__item {
    text-align: center;
  }
</style>
