/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2023-06-11 10:09:56
 * @LastEditors: wenboliu
 * @LastEditTime: 2023-06-17 17:19:15
 */
import Vue from 'vue'

// 使用 Event Bus来实现非父子组件之间的通信
const bus = new Vue()

export default bus
