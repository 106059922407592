<template>
  <!-- 公共侧边栏组件 -->
  <div class="sidebar">
    <!-- default-active：当前激活菜单的 index；
         collapse：是否折叠收起菜单；
         unique-opened：是否只保持一个子菜单的展开，默认为false；
         router：是否使用 vue-router 的模式，启用该模式会在激活导航时以 index 作为 path 进行路由跳转，默认值为false； -->
         <!-- :text-color="$store.state.settings.theme" -->
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="collapse"
      background-color="#fff"
      :active-text-color="colorTheme"
      unique-opened
      router
    >
      <!-- 对items进行遍历 -->
      <template v-for="(item,index) in items">
        <!-- 使用v-if判定是否具有第二级菜单 -->
          <template v-if="item.children.length!==0 && !item.hideChildrenInMenu">
            <el-submenu :index="item.name" :key="index" :style="[{ '--hover-color': hoverColor},{'--hover-borderColor' : hoverBorderColor}]">
              <template slot="title">
                <!-- <i :class="item.meta.icon"></i> -->
                <i :class="item.meta.icon" :style="item.meta.icon === 'iconfont icon-icon_client' ? 'font-size: 16px' : ''"></i>
                <!-- 如果存在第二级菜单，渲染第一级菜单标题 -->
                <span slot="title">{{ item.meta.title }}</span>
              </template>

              <!-- 遍历第二级菜单 -->
              <template v-for="(subItem,index2) in item.children">
                <!-- 判定是否具有第三级菜单 -->
                <el-submenu
                  v-if="subItem.children.length!==0"
                  :index="subItem.name"
                  :key="index2"
                  :style="{ '--hover-color': hoverColor }"
                >
                  <!-- 如果存在第三级菜单，渲染第二级菜单标题 -->
                  <template slot="title">{{ subItem.meta.title }}</template>

                  <!-- 遍历第三级菜单，并渲染第三级菜单标题 -->
                  <el-menu-item
                    v-for="(threeItem,i) in subItem.children"
                    :key="i"
                    :index="threeItem.name"
                    :style="{ '--hover-color': hoverColor }"
                    @click="_toView(threeItem.path,threeItem.redirect)"
                    >{{ threeItem.meta.title }}</el-menu-item
                  >
                </el-submenu>

                <!-- 如果不存在第三级菜单，渲染第二级菜单标题 -->
                <el-menu-item :style="[{ '--hover-color': hoverColor},{'--hover-borderColor' : hoverBorderColor}]"  v-else-if="!subItem.hidden" :index="subItem.name" :key="subItem.name" @click="_toView(subItem.path,subItem.redirect)">{{ subItem.meta.title }}</el-menu-item
                >
              </template>
            </el-submenu>
          </template>
        <!-- 如果不存在第二级菜单，渲染第一级菜单标题 -->
        <template v-else>
          <el-menu-item :style="[{ '--hover-color': hoverColor},{'--hover-borderColor' : hoverBorderColor}]" :index="item.name" :key="index"  @click="_toView(item.path,item.redirect)">
            <!-- <i :class="item.meta.icon"></i> -->
            <i :class="item.meta.icon"></i>
            <span slot="title">{{ item.meta.title }}</span>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
    <div @click="_collapseChage" :class="{'open':isOpen,'close':!isOpen}" :style="{ '--bg-color': openCloseBg }">
      <i class="el-icon-arrow-left"></i>
    </div>
  </div>
</template>

<script>
import bus from '../../utils/bus'
export default {
  data () {
    return {
      collapse: false,
      items: this.$store.state.routersList,
      isOpen: false,
      colorTheme: this.$store.state.settings.theme,
      hoverColor: this._hexToRgb(this.$store.state.settings.theme),
      hoverBorderColor: this.$store.state.settings.theme,
      openCloseBg: this.$store.state.settings.theme
    }
  },
  computed: {
    onRoutes () {
      return this.$route.path.replace('/', '')
    }
  },
  created () {
    if (document.body.clientWidth < 1500) {
      this._collapseChage()
    }
    // 通过 Event Bus 进行组件间通信，来折叠侧边栏，bus.$emit（）用于发送信息。
    bus.$on('collapse', (msg) => {
      this.collapse = msg
      bus.$emit('collapse-content', msg)
    })
    bus.$on('theme', (msg) => {
      // console.log(msg, 'msgggg')
      this.colorTheme = msg
      this.hoverBorderColor = msg
      const hoverColors = JSON.parse(JSON.stringify(msg))
      this.hoverColor = this._hexToRgb(hoverColors)
      this.openCloseBg = msg
    })
  },
  methods: {
    _toView (url, redirect) {
      if (redirect) {
        this.$router.push({ path: redirect })
      } else {
        this.$router.push({ path: url })
      }
    },
    // 侧边栏折叠
    _collapseChage () {
      this.collapse = !this.collapse
      if (this.collapse) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
      // 通过bus发送信息
      bus.$emit('collapse', this.collapse)
    },
    _hexToRgb (hex) {
      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
      // return result ? {
      //   r: parseInt(result[1], 16),
      //   g: parseInt(result[2], 16),
      //   b: parseInt(result[3], 16)
      // } : null
      return result ? 'rgba' + '(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ',' + 0.2 + ')' : null
    }
  }
}
</script>

<!-- CSS样式 -->
<style scoped lang="scss">
  .el-menu-item.is-active {
    background: var(--hover-color)!important;
    border-left: 3px solid var(--hover-borderColor)!important;
  }
  .el-menu-item:hover {
    background-color: var(--hover-color)!important;
    // color:#fff!important
    color:var(--hover-borderColor)
  }
  /deep/.el-submenu.is-active .el-submenu__title i{
    // background: var(--hover-borderColor)!important;
    color:var(--hover-borderColor)!important;
  }
  /deep/.el-submenu.is-active .el-submenu__title{
    color:var(--hover-borderColor)
  }
  // .el-submenu:hover {
  //   background-color: var(--hover-color)!important;
  //   color:#fff!important
  // }
  /deep/.el-submenu .el-submenu__title:hover {
    background-color: var(--hover-color)!important;
    // color:#fff!important
    color:var(--hover-borderColor)
  }
  .sidebar {
    display: flex;
    position: absolute;
    left: 0;
    top: 56px;
    bottom: 0;
    overflow-y: scroll;
  }
  .sidebar::-webkit-scrollbar {
    width: 0;
  }
  .sidebar-el-menu:not(.el-menu--collapse) {
    width: 208px;
  }
  .sidebar > ul {
    height: 100%;
  }
  .sidebar > ul > li {
    margin-bottom: 4px;
  }
  .sidebar-el-menu{
    border-right: 1px solid #f3f3f3;
    z-index: 999;
  }
  .el-menu-item{
    text-align: left;
  }
  .el-submenu{
    text-align: left;
  }
  .open,.close{
    cursor: pointer;
    /* position: fixed; */
    /* width: 14px; */
    height: 74px;
    border-left: 15px solid var(--bg-color)!important;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    left: 208px;
    top: 45%;
    z-index: 999;
    opacity: 1;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-top: 160%;
    margin-left: -1px;
  }
  .close{
    left: 64px;
  }
  .open i{
    margin-left: -17px;
    color: #fff;
  }
  .close i{
    margin-left: -17px;
    color: #fff;
  }
  .el-menu-item, .el-submenu__title{
    height: 36px;
    line-height: 36px;
  }
  /deep/.el-submenu .el-submenu__title{
    height: 36px!important;
    line-height: 36px!important;
  }
  /deep/.el-submenu .el-menu-item{
    height: 36px!important;
    line-height: 36px!important;
  }
  /deep/.iconfont{
    margin-right: 10px;
  }
</style>
