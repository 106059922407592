/*
 * @Descripttion:
 * @version:
 * @Author: wenboliu
 * @Date: 2023-06-11 10:09:56
 * @LastEditors: Andy
 * @LastEditTime: 2023-11-22 00:46:02
 */
/* src/store/modules/settings.js */

import variables from '@/assets/styles/element-variables.scss'

const state = {
  theme: variables.theme
}

const mutations = {
  CHANGE_SETTING: (state, { key, value }) => {
    if (value === 'null') {
      localStorage.setItem('theme', '#0089ff') // 缓存起来，刷新的时候重新取用
    } else {
      localStorage.setItem('theme', value) // 缓存起来，刷新的时候重新取用
    }

    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value
    }
  }
}

const actions = {
  changeSetting ({ commit }, data) {
    commit('CHANGE_SETTING', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
