<!--
 * @Descripttion:
 * @version:
 * @Author: Eugene
 * @Date: 2023-06-05 10:39:33
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-19 21:31:44
-->
<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  computed: {
    defaultTheme () {
      return this.$store.state.settings.theme
    }
  },
  created () {
    // localStorage.removeItem('theme')
  },
  mounted () {
    // if (localStorage.getItem('theme')) {
    //   this.themeChange(localStorage.getItem('theme'))
    // }
  },
  methods: {
    // themeChange (val) {
    //   this.$store.dispatch('settings/changeSetting', {
    //     key: 'theme',
    //     value: val
    //   })
    // }
  }
}
</script>
<style >
*::-webkit-scrollbar {
    width: 2px;
    height: 6px;
}
/* // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。 */
*::-webkit-scrollbar-button {
    display: none;
}
/* // 滚动条的轨道（里面装有Thumb） */
*::-webkit-scrollbar-track {
    background: transparent;
}
/* // 滚动条的轨道（里面装有Thumb） */
*::-webkit-scrollbar-track-piece {
    background-color: transparent;
}
/* // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条） */
*::-webkit-scrollbar-thumb {
    background: rgba(144, 147, 153, 0.3);
    cursor: pointer;
    border-radius: 4px;
}
/* // 边角，即两个滚动条的交汇处 */
*::-webkit-scrollbar-corner {
    display: none;
}
/* // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件 */
*::-webkit-resizer {
    display: none;
}
</style>
<style lang="scss" scoped>
  @import './assets/css/main.css';
  @import './assets/css/color-dark.css'; /*深色主题*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
*{
    scrollbar-color: rgba(144, 147, 153, 0.3) transparent; /* 滑块颜色  滚动条背景颜色 */
    scrollbar-width: thin; /* 滚动条宽度有三种：thin、auto、none */

}

/deep/.hover-title{
  min-width: 120px !important;
  display:-webkit-box;
  text-overflow:ellipsis;
  overflow:hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient:vertical;
}
.hover-name{
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/deep/ .el-card.is-always-shadow{
  box-shadow:none !important;
}
/deep/.el-card__header{
  padding-bottom: 0px !important;
}
/deep/.group-card .el-card__header{
  padding-bottom: 16px !important;
}
/deep/.el-dialog__header{
  padding: 16px 24px !important;
}
/deep/.el-form-item__label{
  color: rgba(0,0,0,0.9) !important;
}
/deep/.add-group-btn {
  width: 130px;
  font-size: 14px;
  border-color:var(--lock-btn)!important;
  color:var(--lock-btn)!important;
}
.width-group-table{
  /deep/.el-table tr td:nth-child(1){
    padding-left: 32px!important;
  }
}
/deep/.el-tree-node:focus > .el-tree-node__content {
  background-color: var(--lock-btn)!important;
  color:var(--lock-btn-text)!important;
}
/deep/.el-tree-node__content:hover {
  background-color: var(--lock-btn)!important;
  color:var(--lock-btn-text)!important;

}
/deep/.el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
  background-color: var(--lock-btn)!important;
  color:var(--lock-btn-text)!important;
}
/deep/.el-progress-bar__inner{
  background-color: #0089ff!important;
}
</style>
