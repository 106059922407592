/*
 * @Descripttion:
 * @version:
 * @Author: Eugene
 * @Date: 2023-06-05 10:39:33
 * @LastEditors: wenwen
 * @LastEditTime: 2024-03-06 14:00:20
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import './assets/styles/element-variables.scss'
import './assets/styles/common.scss'
// 加载默认主题
import 'element-ui/lib/theme-chalk/index.css'
// import './permission'
import axios from 'axios'
// 加载国际化插件
import VueI18n from 'vue-i18n'
import { messages } from './utils/i18n'
// 加载图标样式
import './assets/css/icon.css'
import './assets/css/Icon-css/iconfont.css'
// 加载拖拽组件
import './utils/directives'
// 加载语法编辑器
import 'babel-polyfill'
import './utils/request'
import './menu'
import { Base64 } from 'js-base64'
// import VXETable from 'vxe-table'
// import 'vxe-table/lib/style.css'
import VueQuillEditor from 'vue-quill-editor'
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'
Vue.prototype.$axios = axios
Vue.prototype.$Base64 = Base64
// function getQueryString (name) {
//   const urlString = window.location.href
//   const url = new URL(urlString)
//   return url.searchParams.get(name)
// }
// console.log(getQueryString('projectFlag'), 'projectFlagprojectFlagiouoytry')
// // 如果从浏览器获取项目路径，并获取到参数（首先把参数给存起来）
// if (getQueryString('projectFlag') !== null && getQueryString('projectFlag') !== '' && getQueryString('projectFlag') !== undefined) {
//   store.commit('SET_PROJECTFLAG', getQueryString('projectFlag'))
//   localStorage.setItem('projectFlagStorage', getQueryString('projectFlag'))
//   console.log()
// }
//  else {
//   store.commit('SET_PROJECTFLAG', 'TRAINUSER')
//   localStorage.setItem('projectFlagStorage', 'TRAINUSER')
// }
Vue.config.productionTip = false
// 引入国际化插件
Vue.use(VueI18n)
// 引入ElementUI框架
Vue.use(ElementUI, {
  size: 'small'
})
Vue.use(VueQuillEditor)
// Vue.use(Base64)
// Vue.use(VXETable)
// 实例化国际化插件
const i18n = new VueI18n({
  locale: 'zh',
  messages
})

Vue.use(ElementUI)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')
