<!--
 * @Descripttion:
 * @version:
 * @Author: Eugene
 * @Date: 2023-06-05 10:42:01
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-03-07 10:45:40
-->
<template>
  <!-- 公共Home组件 -->
  <div class="wrapper">
    <!-- 头部组件 -->
    <v-head></v-head>
    <!-- 侧边栏组件 -->
    <v-sidebar></v-sidebar>
    <!-- <div @click="collapseChage" :class="{'open':isOpen,'close':!isOpen}">
      <i class="el-icon-arrow-left"></i>
    </div> -->
    <div class="content-box" :class="{'content-collapse':collapse}">
      <!-- 标签栏组件 -->
      <v-tags></v-tags>
      <div class="content">
        <transition name="move" mode="out-in">
          <!-- keep-alive是Vue的内置组件，能在组件切换过程中将状态保留在内存中
                    include: 字符串或正则表达式。只有匹配的组件会被缓存。
                    exclude: 字符串或正则表达式。任何匹配的组件都不会被缓存。 -->
          <keep-alive :include="tagsList">
            <!-- 次级出口，用以引入次级组件。例如下一个实验中的首页组件 -->
            <router-view></router-view>
          </keep-alive>
        </transition>
        <el-backtop target=".content"></el-backtop>
      </div>
    </div>
  </div>
</template>

<script>
import vHead from './Header.vue'
import vSidebar from './Sidebar.vue'
import vTags from './Tags.vue'
import bus from '../../utils/bus'
export default {
  data () {
    return {
      tagsList: [],
      collapse: false,
      isOpen: false
    }
  },
  components: {
    vHead,
    vSidebar,
    vTags
  },
  created () {
    bus.$on('collapse-content', (msg) => {
      this.collapse = msg
    })
    if (document.body.clientWidth < 1500) {
      this.collapseChage()
    }
    console.log(this.collapse, 'this.collapse')
    // 只有在标签页列表里的页面才使用keep-alive，即关闭标签之后就不保存到内存中了。
    bus.$on('tags', (msg) => {
      const arr = []
      for (let i = 0, len = msg.length; i < len; i++) {
        msg[i].name && arr.push(msg[i].name)
      }
      this.tagsList = arr
    })
  },
  methods: {
    // 侧边栏折叠
    collapseChage () {
      this.collapse = !this.collapse
      if (this.collapse) {
        this.isOpen = false
      } else {
        this.isOpen = true
      }
      // 通过bus发送信息
      bus.$emit('collapse', this.collapse)
    }
  }
}
</script>
<style scoped>
 .open,.close{
    cursor: pointer;
    position: fixed;
    width: 14px;
    height: 74px;
    border-left: 14px solid #0089FF;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    left: 208px;
    top: 45%;
    z-index: 999;
    opacity: 1;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .close{
    left: 64px;
  }
  .open i{
    margin-left: -16px;
    color: #fff;
  }
  .close i{
    margin-left: -16px;
    color: #fff;
  }
</style>
