import axios from 'axios'
import { getCookie } from '@/utils/util'
import { genSignParams, randomString } from './sign'
import { Message } from 'element-ui'

const statusMap = [
  { code: 400, message: '参数有误' },
  { code: 401, message: '未登录' },
  { code: 404, message: '资源不存在' },
  { code: 403, message: '权限不足' },
  { code: 405, message: '请求方法错误' },
  { code: 500, message: '服务器内部错误' },
  { code: 502, message: '网关错误' },
  { code: 504, message: '请求超时' }

]
const request = axios.create({
  // API 请求的默认前缀
  baseURL: process.env.VUE_APP_BASE_API,
  withCredentials: false,
  // withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  }
  // timeout: 6000 // 请求超时时间

})
// 请求拦截器
request.interceptors.request.use(
  (config, Type) => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    if (process.env.NODE_ENV === 'development') {
      document.cookie = 'token=' + process.env.VUE_APP_TOKEN
      // 拆分权限请求
      if (config.url && config.url.indexOf('/permission') === 0) {
        config.baseURL = process.env.VUE_APP_API_BASE_PERMISSION
      }
    }
    const method = config.method
    if (method === 'get' || method === 'delete') {
      const params = config.params ? config.params : {}
      const timestamp = new Date().getTime()
      const appid = '12345678'
      const nonceStr = randomString(8)
      const sign = genSignParams(appid, {
        ...params,
        url: config.url,
        timestamp: timestamp,
        nonceStr: nonceStr
      })
      config.params = {
        ...params,
        timestamp: timestamp,
        nonceStr: nonceStr,
        sign: sign,
        appId: appid
      }
    } else {
      const params = config.data ? config.data : {}
      const timestamp = new Date().getTime()
      const appid = '12345678'
      const nonceStr = randomString(8)
      const sign = genSignParams(appid, {
        body: JSON.stringify(params),
        url: config.url,
        timestamp: timestamp,
        nonceStr: nonceStr
      })
      config.params = {
        timestamp: timestamp,
        nonceStr: nonceStr,
        sign: sign,
        appId: appid
      }

      // if (config.data) {
      //   console.log(config.data, config, '1111')
      // }
    }
    // console.log(config)
    const token = getCookie('token')
    // let token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2MzI1NTYzNDAsInVzZXJJZCI6MTMwMX0.kGjwRk3psmdGTtGNnvcQ3ie6D3KHioBXxqqtoxImmVQ'
    // console.log(router)
    if (token) {
      config.headers.token = token
    } else {
      window.location.href = process.env.VUE_APP_API_BASE_LOGIN + '?' + window.location.href
    }
    return config
  },
  error => {
    return Promise.error(error)
  })

// 响应拦截器
request.interceptors.response.use(
  response => {
    // console.log(response, '抛出')
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    //  console.log(response)
    if (response.status === 200) {
      if (response.data.code !== 200) {
        if (response.data.code === 401) {
          // this.$message({
          //   message: response.data.message,
          //   type: 'error'
          // })
          Message.error(response.data.message)
          localStorage.clear()
          if (process.env.NODE_ENV === 'production') {
            window.location.href = process.env.VUE_APP_API_BASE_LOGIN + '?' + window.location.href
          }
        } else if (response.data.code > 0) {
          if (response.data.code !== 3006) {
            Message.error(response.data.message)
          }
          console.log(response.data, 'response.data')
          // this.$message({
          //   message: response.data.message,
          //   type: 'error'
          // })
          return Promise.reject(response)
        }
        if (response.headers['content-disposition']) {
          return Promise.resolve(response)
        } else {
          return Promise.reject(response)
        }
      }
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  // 服务器状态码不是2开头的的情况
  // 这里可以跟你们的后台开发人员协商好统一的错误状态码
  // 然后根据返回的状态码进行一些操作，例如登录过期提示，错误提示等等
  // 下面列举几个常见的操作，其他需求可自行扩展
  error => {
    if (error.response.status) {
      const errmsg = statusMap.find(item => {
        return error.response.status === item.code
      })
      errmsg || Message.error(errmsg.message) || Message.error('网络接口错误')
      // errmsg || this.$message({
      //   message: errmsg.message,
      //   type: 'error'
      // }) || this.$message({
      //   message: '网络接口错误',
      //   type: 'error'
      // })
      if (error.response.status === 401) {
        window.location.href = process.env.VUE_APP_API_BASE_LOGIN + '?' + window.location.href
      }
      return Promise.reject(error.response)
    }
  }
)
export function get (url, params = {}, Type) {
  return request({
    url: url,
    method: 'get',
    params: params,
    ...Type
  })
}
export function post (url, data = {}, Type) {
  // console.log)
  return request({
    url: url,
    method: 'post',
    data: data,
    ...Type
  })
}
export default request
