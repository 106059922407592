/*
 * @Descripttion:
 * @version:
 * @Author: 张圳
 * @Date: 2023-06-09 14:50:15
 * @LastEditors: Andy
 * @LastEditTime: 2023-06-25 15:07:44
 */
import Vue from 'vue'
import Router from 'vue-router'

// 引入路由插件
Vue.use(Router)

// 防止连续点击多次路由报错
const routerPush = Router.prototype.push
const routerReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location) {
  return routerPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location) {
  return routerReplace.call(this, location).catch(err => err)
}
const routes = [
  // {
  //   path: '/',
  //   name: 'index',
  //   redirect: '/home'
  // },
  // {
  //   path: '/customer',
  //   name: 'customer',
  //   redirect: '/customer/userList',
  //   // name: 'index',
  //   component: () => import('@/views/customer/index.vue'),
  //   meta: { title: '自述文件' }
  //   // redirect: '/home',
  //   // children: [
  //   //   {
  //   //     path: 'home',
  //   //     component: () => import('@/views/home.vue'),
  //   //     meta: { title: '系统首页' }
  //   //   }]
  // },
  // {
  //   path: '/',
  //   name: 'index',
  //   meta: {
  //     title: '看板'
  //   },
  //   component: () => import(/* webpackChunkName: "index" */ '@/components/common/index.vue'),
  //   children: [
  //     {
  //       path: '/home',
  //       name: 'home',
  //       meta: {
  //         title: '看板'
  //       },
  //       component: () => import(/* webpackChunkName: "index" */ '@/views/home.vue')
  //     }
  //   ]
  // },
  // {
  //   path: '/404',
  //   meta: {
  //     title: '未找到'
  //   },
  //   component: () => import('../views/404.vue')
  // }
]
const router = new Router({
  mode: 'history',
  routes

})

router.afterEach((to, from, next) => {
  // 添加网站标题
  if (to.meta.title && typeof (to.meta.title) === 'string') {
    if (to.path === '/') {
      document.title = '英奢集团'
    } else {
      document.title = to.meta.title + '-英奢集团'
    }
  }
})

export default router
