/*
 * @Descripttion:
 * @version:
 * @Author: 郭梦霞
 * @Date: 2023-06-24 16:37:14
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-02-26 11:28:01
 */

import request, { get } from './permission'
const API = {
  menu: '/permission/menu/menus',
  btn: '/permission/menu/chlidren',
  currentUser: '/report/user/currentUser'
}
export const getUserApi = () => get(API.currentUser)
export function getMenusApi () {
  return request({
    url: API.menu,
    method: 'get',
    params: {
      businessLineTag: 'HUMAN_RESOURCE',
      projectTag: 'TRAINUSER'
    }
  })
}
export function getBtnApi () {
  return request({
    url: API.btn,
    method: 'get',
    params: {
      businessLineTag: 'HUMAN_RESOURCE',
      projectTag: 'TRAINUSER'
    }
  })
}
