import md5 from 'md5'
function sorter (inObject) {
  var sortedJson = {}
  var sortedKeys = Object.keys(inObject).sort()
  for (var i = 0; i < sortedKeys.length; i++) {
    sortedJson[sortedKeys[i]] = inObject[sortedKeys[i]]
  }
  return sortedJson
}
export function genSignParams (secretKey, inObject) {
  inObject.appId = secretKey
  var sortedParam = sorter(inObject)
  var needSignatureStr = ''
  for (var key in sortedParam) {
    var value = sortedParam[key]
    if (value === undefined || value === null || value === '') {
      continue
    }
    if (typeof value === 'object') {
      value = JSON.stringify(value)
    }
    needSignatureStr = needSignatureStr + key + '=' + value + '&'
  }
  needSignatureStr += 'key=abcdefghijklmnopqrstuvwxyz'
  if (process.env.NODE_ENV === 'development') {
    // console.log(needSignatureStr)
  }

  return md5(needSignatureStr).toUpperCase()
}
export function randomString (e) {
  e = e || 32
  var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
  var a = t.length
  var n = ''
  for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
  return n
}
